.mangerAppointment {
  padding: 0;
}

.mangerAppointment input {
  width: 100% !important;
}

.mangerAppointment .manager-inputs {
  margin: 15px !important;
  margin-left: 0 !important;
  display: block;
}

.mangerAppointment .appointment-container .inputBox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0 !important;
}

.mangerAppointment .appointment-container .inputBox input:nth-child(1) {
  width: 80% !important;
}

.mangerAppointment
  .appointment-container
  .inputBox
  input:nth-child(1)::placeholder {
  text-align: left;
}

.mangerAppointment .appointment-container .inputBox input:nth-child(2) {
  width: 20% !important;
  color: #777;
}

@media (max-width: 767px) {
  .mangerAppointment .appointment-container {
    padding: 20px;
    margin-top: 100px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mangerAppointment .appointment-container {
    padding: 20px;
    margin-top: 100px;
  }
}
