.appointment {
  padding: 120px 0;
  margin: 0 !important;
}

.appointment .table {
  width: 100%;
  table-layout: auto;
  background: #000 !important;
}

.appointment .appointment-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointment .appointment-container .selection-box {
  border: 1px solid #e7ddcf;
  padding: 10px;
}

.appointment .appointment-container td {
  margin: 0 !important;
}

.appointment .appointment-container .selections-box .selection-box {
  margin-bottom: 10px;
}

.appointment .appointment-container form p {
  margin-top: 15px;
  color: red !important;
}

.appointment .inputs-box {
  display: flex;
}

.appointment .inputs-box label {
  display: block;
  margin-bottom: 15px;
}

.appointment .inputs-box div {
  margin: 20px;
  margin-right: 0 !important;
}

.appointment .appointment-container .selectionList {
  display: flex;
  width: 100%;
  gap: 30px;
}

.appointment .appointment-container .selectionList .selections-box,
.appointment .appointment-container .selectionList .selection-box,
.appointment .appointment-container .selectionList .warrantyBox {
  width: 100%;
}

.appointment .inputs-box div {
  width: 100% !important;
}

.appointment .inputs-box div:nth-child(3) {
  margin: 20px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.appointment input,
.appointment textarea {
  border: 1px solid #e7ddcf;
  padding: 5px;
}

.appointment .textarea-box {
  margin: 20px 0;
}

.appointment textarea {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
}

.appointment .inputs-box .phoneNum {
  margin-left: 0 !important;
}

.appointment .inputs-box1,
.appointment .inputs-box1 input,
.appointment .inputs-box1 div {
  width: 100%;
}

.disabled-style {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .appointment .appointment-container {
    display: block;
  }
  .appointment .inputs-box {
    flex-direction: column;
  }
  .appointment .inputs-box input {
    width: 100% !important;
  }
  .appointment .inputs-box div {
    margin: 0;
  }
}
