.hero {
  background-image: url("../../assets/Pictuers/herobg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  margin: 0 !important;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
  opacity: 0.7;
  z-index: 1;
}

.hero .hero-container .text-box,
.hero .hero-container .image-box {
  position: relative;
  z-index: 99;
  text-align: center;
}

.hero .hero-container .text-box h2 {
  font-weight: bold;
  font-size: 64px;
}

.hero .hero-container .text-box p {
  font-size: 24px;
  margin: 32px 0;
}

.hero .hero-container .text-box button {
  width: 240px;
  height: 56px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

@media screen and (max-width: 767px) {
  .hero .hero-container .text-box h2 {
    font-size: 30px;
  }
  .hero .hero-container .text-box p {
    font-size: 20px;
  }
  .hero .hero-container .text-box button {
    width: 180px;
    height: 56px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hero .hero-container .text-box h2 {
    font-size: 40px;
  }
}
