.contact .contact-container .contact-right h1 {
  font-weight: bold;
}

.contact .contact-container .row {
  align-items: center;
}

.contact .contact-right .input-box input {
  margin: 25px 0;
}

.contact .contact-right .input-box input,
.contact .contact-right .textarea-box textarea {
  background-color: var(--main-color);
  color: #ffffff;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
}

.contact .contact-right .textarea-box textarea {
  height: 170px;
}

.contact .contact-right .button-box button {
  width: 100%;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
