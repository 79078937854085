.warrantyChart {
  margin: 0px 0;
}

.warrantyChart h3 {
  margin-bottom: 20px;
}

.warrantyChart .box,
.warrantyChart .boxWarr {
  display: flex;
  align-items: center;
  gap: 10px;
}

.warrantyChart .box .empty {
  background: #ffaa57;
  width: 15px;
  height: 15px;
}

.warrantyChart .box .emptyI {
  background: #8bd7d7;
  width: 15px;
  height: 15px;
}

.warrantyChart .boxWarr .empty {
  background: rgb(255, 99, 132);
  width: 15px;
  height: 15px;
}

.warrantyChart .boxWarr .emptyI {
  background: rgb(54, 162, 235);
  width: 15px;
  height: 15px;
}
