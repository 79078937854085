.testimonils {
  background: url("../../assets/Pictuers/testimonials.png");
  background-position: 50% 20%;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 50px 0;
  position: relative;
}

.testimonils .overlay {
  background: #0e8ece;
  opacity: 0.9;
}

.testimonils .testimonils-container .testimonils-right,
.testimonils .testimonils-container .testimonils-left {
  position: relative;
  z-index: 1;
}

.testimonils .testimonils-container .testimonils-right img {
  height: 70%;
}

.testimonils .testimonils-container .row {
  gap: 50px;
  align-items: center;
}

.testimonils .testimonils-container .testimonils-left h1 {
  font-size: 64px;
  font-weight: bold;
}

.testimonils .testimonils-container .testimonils-left svg,
.testimonils .testimonils-container .testimonils-left h5 {
  margin: 40px 0;
}

.testimonils .testimonils-container .testimonils-left {
  padding-right: 40px;
}
