.eid {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background-image: url("../../assets/Pictuers/herobg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  color: #fff;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
  opacity: 0.7;
  z-index: 1;
}

.eid-container {
  position: relative;
  z-index: 99;
}

.eid .eid-container h1 {
  font-weight: bold;
  font-size: 64px;
}

.eid .eid-container p {
  font-size: 24px;
  margin: 32px 0;
}
