.warrantyChart {
  background: #fbfafa;
  padding: 10px;
  margin-bottom: 20px;
}

.warrantyChart h2 {
  font-size: 24px;
  font-weight: bold;
}
