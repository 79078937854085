nav {
  position: fixed !important;
  width: 100vw;
  background: var(--main-color);
  display: grid;
  place-items: center;
  z-index: 999 !important;
}

/* only show on medium and small screens */
.nav__toggle-btn {
  display: none;
  color: var(--main-color) !important;
}

.navbar .navbar-toggler {
  font-size: 25px;
  border: none;
  outline: none;
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

.navbar .navbar-toggler:hover {
  background-color: transparent !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.nav__container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

nav img {
  width: 110px;
  height: 80px;
}

nav .nav-link {
  margin-left: 20px;
  display: flex;
  gap: 3.5rem;
  align-items: center;
  font-weight: bold;
  color: #fff;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

nav .nav-link:hover {
  color: var(--primary-color);
}

nav .nav-link:focus {
  color: var(--primary-color);
}

nav .button button {
  width: 200px;
  height: 56px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

nav .button button,
nav .button button svg {
  font-weight: bold;
}

nav .button button svg {
  margin-right: 8px;
}

nav .button button:hover,
nav .button button:hover svg path {
  color: var(--primary-color) !important;
  stroke: var(--primary-color) !important;
}

@media (max-width: 992px) {
  .collapse {
    transition: height 0.3s ease;
    overflow: hidden;
  }

  .collapse.show {
    height: auto;
  }

  .navbar-toggler.collapsed + .navbar-collapse {
    height: 0;
  }
}
