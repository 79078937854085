.technicalScreen .technicalScreen-container h2 {
  margin-bottom: 40px;
  color: var(--main-color);
}

.technicalScreen table {
  table-layout: fixed;
  min-width: 1200px;
  overflow: auto;
  width: 100%;
}

thead,
th {
  position: sticky;
}

.technicalScreen .technicalScreen-container .head button {
  height: 40px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.technicalScreen .technicalScreen-container ul a li:hover {
  background: var(--main-color);
}

.technicalScreen .technicalScreen-container td a {
  color: #000;
}

.technicalScreen .technicalScreen-container .btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.technicalScreen .technicalScreen-container button {
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  background: var(--primary-color);
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.technicalScreen .technicalScreen .technicalScreen-container button:hover {
  color: #fff;
  background: var(--main-color);
}

.buttons-box {
  gap: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .technicalScreen .technicalScreen-container {
    padding: 20px;
    margin-top: 100px;
  }
  .technicalScreen .technicalScreen-container button {
    padding: 5px !important;
    font-size: 12px;
  }
  .technicalScreen table {
    min-width: 550px;
    width: 100%;
  }
  .technicalScreen .buttons-box {
    flex-direction: row;
  }
  .technicalScreen th:nth-child(1) {
    width: 25px !important;
  }
  .technicalScreen th:nth-child(2) {
    width: 35px !important;
  }
  .technicalScreen th:nth-child(3) {
    width: 35px !important;
  }
}

@media (max-width: 767px) {
  .technicalScreen .technicalScreen-container {
    padding: 20px;
    margin-top: 100px;
  }
  body {
    font-size: 14px;
  }
  .technicalScreen table {
    min-width: 550px;
    width: 100%;
  }
  .btn-box {
    display: flex;
    flex-direction: column;
  }
  .technicalScreen .buttons-box {
    flex-direction: column;
  }
  .technicalScreen .col {
    width: 100% !important;
    padding-top: 100px !important;
    margin-right: 17px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .technicalScreen .row {
    width: 100%;
  }
  .technicalScreen .technicalScreen-container .head button {
    height: 100%;
  }
  .technicalScreen h2 {
    font-size: 1rem;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .technicalScreen .technicalScreen-container {
    padding: 20px;
  }
  .technicalScreen .buttons-box {
    flex-direction: row;
  }
  .technicalScreen th:nth-child(1) {
    width: 20px !important;
  }
  .technicalScreen th:nth-child(2) {
    width: 30px !important;
  }
  .technicalScreen th:nth-child(3) {
    width: 30px !important;
  }
  .technicalScreen table {
    table-layout: fixed;
    min-width: 0;
    overflow: auto;
    width: 100%;
  }
}
