.rejectedList .info h3 {
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rejectedList table {
  table-layout: fixed;
  min-width: 1000px;
  overflow: auto;
  width: 100%;
}

thead,
th {
  position: sticky;
}

.rejectedList .buttons button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.rejectedList .btn-holder button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border: 2px solid var(--primary-color);
}

.rejectedList .btn-holder button:hover {
  background: transparent !important;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.rejectedList .btn-holder a button {
  background: transparent !important;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.rejectedList .btn-holder a button:hover {
  background: var(--primary-color) !important;
  color: #fff;
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
  .table {
    width: 100%;
  }
  .btn-box {
    display: flex;
    flex-direction: column;
  }
  .newAppointment .newAppointment-container h2 {
    font-size: 18px;
  }
  .rejectedList button {
    font-size: 13px;
    padding: 5px 12px;
  }
  .rejectedList .buttons {
    margin-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .rejectedList .buttons {
    margin-left: 0px;
  }
}
