.addAcTypes {
  margin: 0 !important;
}

.addAcTypes .acAddTypesForm {
  text-align: center;
}

.addAcTypes .col {
  padding-right: 0 !important;
  width: 80%;
}

.addAcTypes table {
  table-layout: fixed;
  min-width: 500px;
  width: 100%;
  overflow: auto;
}

thead,
th {
  position: sticky;
}

.addAcTypes .acAddTypesForm .inputBox {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.addAcTypes .acAddTypesForm h6 {
  font-weight: bold;
}

.addAcTypes .acAddTypesForm input {
  border: 1px solid #eeeeee;
  padding: 10px;
  height: 100%;
  width: 20%;
}

.addAcTypes .acAddTypesForm button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border: 2px solid var(--primary-color);
}

.addAcTypes .info h3 {
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addAcTypes .addAcTypes-container ul a li:hover {
  background: var(--main-color);
}

.addAcTypes .addAcTypes-container .addAcTypes p {
  color: #fff;
}

.addAcTypes .addAcTypes-container td,
.addAcTypes .addAcTypes-container th {
  padding-right: 25px;
}

.addAcTypes .addAcTypes-container td a {
  color: #000;
}

.addAcTypes .addAcTypes-container tbody button,
.addAcTypes .addAcTypes-container tbody a {
  text-align: center;
  color: #fff;
  padding: 5px 15px;
  background: var(--primary-color);
  transition: var(--transition);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.addAcTypes .addAcTypes-container tbody button:hover,
.addAcTypes .addAcTypes-container tbody a:hover {
  background: var(--main-color);
}

.addAcTypes .btn-holder a {
  border: 2px solid var(--primary-color);
}

.addAcTypes .btn-holder a:hover {
  background-color: transparent !important;
  color: var(--primary-color) !important;
}

.addAcTypes .btn-holder button {
  border: 2px solid var(--primary-color);
  background-color: transparent !important;
  color: var(--primary-color) !important;
}

.addAcTypes .btn-holder button:hover {
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

@media (max-width: 1024px) {
  .addAcTypes .addAcTypes-container {
    padding-left: 20px;
    margin-top: 100px;
  }
  .addAcTypes .acAddTypesForm input {
    width: 50%;
  }
  .addAcTypes .addAcTypes-container .info {
    margin: 25px 10px;
  }
}

@media (max-width: 992px) {
  .addAcTypes .addAcTypes-container {
    padding: 20px;
    margin-top: 100px;
  }
  .addAcTypes .acAddTypesForm button {
    transform: translateX(-55px);
    -webkit-transform: translateX(-55px);
    -moz-transform: translateX(-55px);
    -ms-transform: translateX(-55px);
    -o-transform: translateX(-55px);
  }
}