section.login-page {
  margin: 0 !important;
  padding-top: 180px;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.login-container {
  text-align: center;
}

.login-container .logo {
  width: 200px;
  margin-right: 0;
  display: inline-block;
  margin-bottom: 20px;
}

.login-container .input {
  margin: 10px auto;
  max-width: 400px;
  text-align: right;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #cccdf1 !important;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.login-container .input:hover {
  border: 1px solid var(--primary-color);
}

.login-container .input input {
  border: none !important;
}

.login-container button {
  color: #fff;
  background: var(--primary-color);
  padding: 10px;
  max-width: 400px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.login .login-container .text-wraper {
  margin: 20px 0;
}

.login .login-container .error {
  color: var(--primary-color);
  padding: 0;
}

.login .login-container .para1 {
  color: var(--primary-color);
  cursor: pointer;
}

.login .login-container .para {
  color: #444;
}

.login .login-container button {
  padding: 20px;
}

.login .login-container .reg-btn {
  background: var(--secondary-color);
}

@media screen and (max-width: 767px) {
  .login-container .input {
    width: 300px;
  }
}
