section.features {
  margin: 40px 0 !important;
}

.features .features-container .row {
  margin-bottom: 80px;
}

.features .features-container .column {
  padding-left: 1px !important;
  padding-right: 30px !important;
}

.features .features-container .features-left .image-box {
  background-color: var(--main-color);
}

.features .features-container .features-left img {
  width: 500px;
  margin: 5px auto;
}

.features .features-container .features-right .text-box h2 {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
}

.features .features-container .features-right .text-box p {
  font-size: 34px;
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .features .features-container .row:nth-child(1),
  .features .features-container .row:nth-child(3) {
    flex-direction: column-reverse;
  }
  .features .features-container .column {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .features .features-container .column {
    margin-bottom: 30px;
  }

  .features .features-container .features-left img {
    width: 350px;
    margin: 5px auto;
  }

  .features .features-container .features-right .text-box h2 {
    font-size: 30px;
  }

  .features .features-container .features-right .text-box p {
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .features .features-container .row:nth-child(1),
  .features .features-container .row:nth-child(3) {
    flex-direction: column-reverse;
  }
  .features .features-container .column {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .features .features-container .column {
    margin-bottom: 30px;
  }
}
