section.otpPage {
  margin: 0 !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 9999;
  border-radius: 20px;
  overflow: hidden;
  border: none !important;
}

.otp-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dark background with 80% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.otpPage .otpPage-container {
  padding: 40px 20px;
  width: 400px;
  height: auto;
}

.otpPage h2 {
  background-color: var(--primary-color);
  color: #fff;
  padding: 20px;
  font-size: 20px;
}

.otpPage .otpPage-container p.intro {
  color: #000000;
  width: 70%;
  font-weight: bold;
}

.otpPage .otpPage-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.otpPage .otp-input-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
}

.otpPage .otpPage-container input {
  margin: 10px 0 20px 0;
  padding: 15px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: block;
}

.otpPage .otpPage-container .otp-input:focus {
  outline: 4px solid #0e8ece6e !important;
}

.otpPage .otpPage-container .button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.otpPage .otpPage-container button {
  width: 100%;
  text-align: left;
  padding: 8px 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.otpPage .otpPage-container .button-box button:nth-child(1) {
  border: 2px solid var(--primary-color);
  background: transparent;
  color: var(--primary-color);
}

.otpPage .otpPage-container .error {
  color: red;
}


@media (max-width: 1024px) {
  .otpPage .otpPage-container {
    width: 100%;
  }
  .otpPage .otpPage-container input {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .otpPage .otpPage-container {
    width: 300px;
  }
}
