section.about {
  margin: 40px 0 !important;
  margin-bottom: 0 !important;
  padding: 50px 0;
  background: var(--primary-color);
  color: #fff;
}

.about .headline p {
  font-size: 20px;
  max-width: 50%;
  margin: 10px auto;
  font-size: 30px;
  margin-top: 30px;
}

.about .about-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin-top: 150px;
}

.about .about-container .Col {
  background-color: #fff;
  border: 2px solid var(--main-color);
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  padding: 10px;
  color: #000000;
  height: 730px;
  max-height: 100% !important;
  max-width: 100% !important;
}

.about .about-container .seconedCol {
  background-color: var(--main-color);
  border: 2px solid #fff;
  color: #fff;
  transform: translateY(-100px);
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  -o-transform: translateY(-100px);
  height: 830px;
  max-height: 100% !important;
}

.about .about-container .seconedCol .image-box img {
  height: 270px !important;
}

.about .about-container .image-box img {
  margin: 10px auto;
  width: 300px;
  height: 200px !important;
  margin-bottom: 50px;
}

.about .about-container .description {
  padding-bottom: 20px;
}

.about .about-container .description .text-box .paragraph {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.about .about-container .description .text-box {
  font-size: 15px;
  font-weight: bold;
}

.about .about-container .description .text-box svg {
  background-color: #2dc071;
  padding: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.about .about-container .description .text-box p {
  font-size: 16px;
  font-weight: bold;
}
