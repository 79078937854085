.chartsReport .chartsHeder button {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.charts-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@media (max-width: 1024px) {
  .chartsReport .chartsReport-container {
    padding: 20px;
    margin-top: 100px;
  }
  .chartsReport .chartsHeder {
    margin: 20px;
  }
  .chartsReport .row {
    margin-right: 0px;
  }
  .chartsReport .sidebarCol {
    display: none;
  }
  .chartsReport .mainCol {
    width: auto;
  }
}

@media (max-width: 767px) {
  .chartsReport .chartsReport-container {
    padding: 20px;
    margin-top: 100px;
  }
  .chartsReport .chartsHeder {
    margin: 0 !important;
  }
  .warrantyChart .warrantyChart-container {
    padding-left: 0;
  }
  .charts-box {
    grid-template-columns: 1fr;
  }
}
