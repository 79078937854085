.addSpareParts {
  margin: 0 !important;
}

.addSpareParts .addSpareParts-container {
  position: relative;
}

.addSpareParts table {
  table-layout: fixed;
  min-width: 100%;
  overflow: auto;
}

thead,
th {
  position: sticky;
}

.addSpareParts .addSparePartsForm {
  text-align: center;
}

.addSpareParts .addSparePartsForm button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.addSpareParts .addSparePartsForm .inputBox {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.addSpareParts .addSparePartsForm h6 {
  font-weight: bold;
}

.addSpareParts .addSparePartsForm input {
  border: 1px solid #eeeeee;
  padding: 10px;
  height: 100%;
  width: 20%;
}

.addSpareParts .info h3 {
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addSpareParts .addSpareParts-container ul a li:hover {
  background: var(--main-color);
}

.addSpareParts .addSpareParts-container .addAcTypes p {
  color: #fff;
}

.addSpareParts .addSpareParts-container td a {
  color: #000;
}

.addSpareParts .addSpareParts-container tbody button,
.addSpareParts .addSpareParts-container tbody a {
  text-align: center;
  color: #fff;
  padding: 5px 15px;
  background: var(--primary-color);
  transition: var(--transition);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.addSpareParts .addSpareParts-container tbody button:hover,
.addSpareParts .addSpareParts-container tbody a:hover {
  background: var(--main-color);
}

.addSpareParts .btn-holder a {
  border: 2px solid var(--primary-color);
}

.addSpareParts .btn-holder a:hover {
  background-color: transparent !important;
  color: var(--primary-color) !important;
}

.addSpareParts .btn-holder button {
  border: 2px solid var(--primary-color);
  background-color: transparent !important;
  color: var(--primary-color) !important;
}

.addSpareParts .btn-holder button:hover {
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

@media (max-width: 767px) {
  .addSpareParts .addSpareParts-container {
    padding: 20px;
    margin-top: 100px;
  }
  .addSpareParts .addSparePartsForm input {
    width: 50%;
  }
  .addSpareParts .addSparePartsForm button {
    transform: translateX(-75px);
    -webkit-transform: translateX(-75px);
    -moz-transform: translateX(-75px);
    -ms-transform: translateX(-75px);
    -o-transform: translateX(-75px);
  }
  .addSpareParts .btn-holder {
    display: flex;
    gap: 5px;
  }
  .addSpareParts .btn-holder button {
    margin: 0 !important;
  }
  .addSpareParts th:nth-child(1) {
    width: 250px !important;
  }
  .addSpareParts th:nth-child(2) {
    width: 170px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .addSpareParts .addSpareParts-container {
    margin-top: 100px;
    padding: 20px;
  }
  .addSpareParts .addSparePartsForm input {
    width: 50%;
  }
  .addSpareParts th:nth-child(1) {
    width: 50px !important;
  }
  .addSpareParts th:nth-child(2) {
    width: 50px !important;
  }
}
