.footer {
  background: var(--main-color);
  padding: 50px 0 0 0;
  margin-bottom: 0 !important;
  color: #fff;
}

.footer .footer-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 44px;
}

.footer .footer-container img {
  width: 200px;
}

.footer .footer-container ul li {
  color: #ffffff;
  opacity: 0.5;
  margin: 10px;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.footer .footer-container .fastNav a {
  color: #ffffff;
}

.footer .footer-container a {
  color: #ffffff;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.footer .footer-container a:hover,
.footer .footer-container ul li:hover {
  color: var(--primary-color);
}

.footer .footer-container .socialMedia ul {
  display: flex;
  padding-left: 0;
}

.footer .footer-container .socialMedia ul li {
  opacity: 1;
}

.footer .footer-container .socialMedia ul li img {
  background: var(--primary-color);
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.footer .copyright {
  text-align: left;
  margin-top: 30px;
}

.footer .copyright p {
  margin-bottom: 0 !important;
  padding-bottom: 40px;
  color: #ffffff;
  opacity: 0.5;
}
