.requestDetails {
  margin-top: 0;
}

.requestDetails .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.requestDetails .requestDetails-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
}

.requestDetails .data-container h5 {
  opacity: 0.7;
  font-size: 16px;
  font-weight: bold;
}

.requestDetails .data-container h5.title {
  opacity: 1;
  font-weight: bold;
  margin: 30px 0;
}

.requestDetails .img-holder img {
  height: 400px;
}

.requestDetails .data-container h6,
.requestDetails .clientDetails a {
  font-size: 16px;
  font-weight: normal;
  color: black;
}

.requestDetails p {
  text-align: center;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.requestDetails .newReq {
  color: #fff;
  background: #59bfff;
}

.requestDetails .approvedReq {
  color: #008236;
  background: #cbffd2;
}

.requestDetails .rejectedReq {
  color: #fe3c53;
  background: #f4d1c6;
}

.requestDetails .inProgress {
  color: #d78b08;
  background: #f5ead8;
}

.requestDetails .maintainceDone {
  color: #000000;
  opacity: 0.7;
  background: #9cc4f2;
}

.requestDetails .clientDetails h3 {
  margin-bottom: 30px;
}

.requestDetails .clientDetails .largeBox {
  gap: 30px;
}

.requestDetails .data-container .ACdetails .box {
  margin: 25px 0;
}

.requestDetails .techReportFrom {
  margin: 20px 0;
}

.techReportFrom textarea {
  display: block;
  border: 1px solid #eeeeee;
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.requestDetails .box {
  display: flex;
  margin-left: 20px;
  gap: 20px;
}

.requestDetails button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.requestDetails .techReportFrom p {
  color: var(--primary-color) !important;
}

.requestDetails .requestDetails-container .imageDetails {
  width: 250px;
}

@media (max-width: 767px) {
  .requestDetails {
    margin-top: 100px;
  }
  .requestDetails .header {
    padding: 0 20px;
  }
  .requestDetails .requestDetails-container {
    grid-template-columns: 1fr;
    padding: 20px;
  }
  .requestDetails .mainCol {
    display: block !important;
    margin: 25px auto;
    margin-right: 45px;
  }
  .requestDetails .smCol {
    display: none !important;
  }
  .requestDetails .box {
    gap: 5px;
  }
  .requestDetails .data-container h5,
  .requestDetails .data-container h6 {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .requestDetails {
    margin-top: 100px;
  }
  .requestDetails .header {
    padding: 0 20px;
  }
  .requestDetails .requestDetails-container {
    padding: 20px;
  }
  .requestDetails .mainCol {
    margin: 25px auto;
    margin-right: 45px;
  }
  .requestDetails .smCol {
    display: none !important;
  }
  .requestDetails .box {
    gap: 5px;
  }
  .requestDetails .data-container h5,
  .requestDetails .data-container h6 {
    font-size: 13px;
  }
}
