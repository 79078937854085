.updateAc {
  margin: 0 !important;
  padding-top: 200px;
}

.updateAc .acAddTypesForm {
  width: 50%;
  height: 100%;
  border: 1px solid #e7ddcf;
  margin: 10px auto;
  padding: 20px;
}

.updateAc .acAddTypesForm label {
  margin-left: 10px;
  display: block;
}

.updateAc .acAddTypesForm input {
  width: 100%;
  height: 100%;
  margin: 20px 0;
}

.updateAc .acAddTypesForm button {
  text-align: center;
  margin-top: 15px;
  width: 50%;
}
