.waitingList td.waitingBtn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  padding: 8px 0;
}

.waitingList .waitingBtn button,
.waitingList .waitingBtn a.link button {
  flex: 1;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  text-align: center;
  width: 100%;
  height: auto;
}

.waitingList table {
  table-layout: fixed;
  min-width: 1200px;
  overflow: auto;
  width: 100%;
}

thead,
th {
  position: sticky;
}

.waitingList .head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.waitingList .head button {
  height: 40px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

@media (max-width: 767px) {
  .newAppointment-container {
    margin-top: 100px;
    padding: 20px;
  }
  body {
    font-size: 14px;
  }
  .table {
    width: 100%;
  }
  .btn-box {
    display: flex;
    flex-direction: column;
  }
  .waitingList table {
    min-width: 1200px !important;
    width: 100%;
  }
  .waitingList .head {
    margin: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .newAppointment-container {
    margin-top: 100px;
    padding: 20px;
  }
  .waitingList .selection-box {
    width: 88px !important;
  }
  .waitingList select {
    width: 85px !important;
  }
  th,
  thead {
    width: 70px !important;
  }
  .table {
    margin-right: 0;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .waitingList .selection-box {
    width: 100px !important;
  }
  .waitingList select {
    width: 100% !important;
  }
  th,
  thead {
    width: 70px !important;
  }
  .waitingList .head {
    display: flex;
    justify-content: space-between;
    margin: 0px;
  }
  .waitingList button {
    padding: 5px 10px;
  }
}
