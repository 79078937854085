.cancelledRequests {
  padding-right: 0 !important;
}
.cancelledRequests button {
  margin-top: 0 !important;
}

.cancelledRequests table {
  min-width: 1100px;
}

@media (max-width: 767px) {
  .cancelledRequests table {
    min-width: 1100px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cancelledRequests .buttons {
    margin-left: 0px;
  }
  .cancelledRequests table {
    min-width: 1000px;
  }
}
