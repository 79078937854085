section.team {
  margin: 0px 0 !important;
  padding: 50px 0;
  background: var(--main-color);
  color: #fff;
}

.team .headline p {
  max-width: 50%;
  margin: 10px auto;
  font-size: 30px;
  margin-top: 30px;
}

.team .team-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 44px;
}

.team .team-container .team-card {
  height: 700px;
}

.team .team-container .team-card .image-box img {
  outline: 10px solid #0e8ece;
  height: 560px;
  border-radius: 4rem 0 4rem 0;
  -webkit-border-radius: 4rem 0 4rem 0;
  -moz-border-radius: 4rem 0 4rem 0;
  -ms-border-radius: 4rem 0 4rem 0;
  -o-border-radius: 4rem 0 4rem 0;
}

.team .team-container .team-card .text-box h3 {
  text-align: center;
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .hero .hero-container .text-box h2 {
    font-size: 30px;
  }

  .team .headline p {
    font-size: 20px;
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hero .hero-container .text-box h2 {
    font-size: 40px;
  }

  .team .headline p {
    font-size: 20px;
    max-width: 60%;
  }
}
