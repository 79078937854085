.currentStatus {
  background-color: rgba(129, 114, 114, 0.521);
  color: #fff;
}

.reportes table {
  table-layout: fixed;
  min-width: 1000px;
  overflow: auto;
  width: 100%;
}

thead,
th {
  position: sticky;
}

.reportes .buttons div {
  display: flex;
  gap: 10px;
}

.reportes .reportes-container .form div {
  display: flex;
}

.reportes .buttons h3 {
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reportes .reportes-container p {
  text-align: center;
  width: 100%;
  padding: 2px 4px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.reportes .reportes-container .form-holder {
  background-color: rgba(0, 0, 0, 0.125);
  padding: 20px;
  margin: 50px 0;
}

.reportes .reportes-container .form-holder input {
  margin-left: 15px;
  width: 100%;
}

.reportes .reportes-container .form-holder select,
.reportes .reportes-container .form-holder .selection-box select {
  height: 100% !important;
}

.reportes .reportes-container .form-holder h5 {
  display: block;
  font-weight: bold;
}

.reportes .reportes-container form {
  display: flex;
  justify-content: space-between;
}

.reportes .reportes-container form button {
  width: 25%;
}

.reportes .reportes-container input {
  border: none !important;
  padding: 10px;
  width: 30%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.reportes .reportes-container .selection-box {
  margin-left: 15px;
  height: 100% !important;
  padding: 2px;
  border: none !important;
  background-color: #fbfafa !important;
  border-radius: 5px;
}

.reportes .reportes-container .selection-box select {
  border: none !important;
  height: 100% !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.reportes .newReq {
  color: #fff;
  background: #59bfff;
}

.reportes .approvedReq {
  color: #008236;
  background: #cbffd2;
}

.reportes .rejectedReq {
  color: #fe3c53;
  background: #f4d1c6;
}

.reportes .inProgress {
  color: #d78b08;
  background: #f5ead8;
}

.reportes .maintainceDone {
  color: #000000;
  opacity: 0.7;
  background: #9cc4f2;
}

.reportes .reportes-container h2 {
  color: var(--main-color);
  font-weight: bold;
}

.reportes .reportes-container ul {
  padding-left: 0;
}

.reportes .reportes-container ul a li {
  margin-bottom: 50px;
  margin-left: 20px;
  background: var(--primary-color);
  color: #fff;
  padding: 5px 10px;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.reportes .reportes-container ul a li:hover {
  background: var(--main-color);
}

.reportes .reportes-container button {
  color: #fff;
  border-radius: 8px;
  background: var(--primary-color);
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.reportes .reportes-container button:hover {
  color: #fff;
  background: var(--main-color);
}

.reports .reportes-container .charts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-data-message {
  font-weight: 600;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item:focus,
.page-link:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.reportes .reportes-container .printButton {
  width: 20%;
  height: 40px;
}

.reportes .reportes-container .paginateComponent {
  display: flex;
  justify-content: end;
  gap: 20px;
}

.reportes .reportes-container .paginateComponent button {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.reportes .reportes-container .page-link {
  margin: 0 8px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: #fbfafa;
  color: #000000;
  border: none;
}

.reportes .reportes-container .active > .page-link,
.page-link.active {
  background: var(--primary-color);
  color: #fff;
}

@media (max-width: 1024px) {
  .reportes .reportes-container {
    max-width: 100%;
    margin-right: 0px !important;
    margin-top: 100px;
    padding: 20px;
  }
  .reportes .reportes-container .form-holder .form {
    display: flex;
    flex-direction: column;
  }
  .reportes .reportes-container .form-holder .form div:nth-child(1),
  .reportes .reportes-container .form-holder .form div:nth-child(2) {
    display: flex;
    margin-bottom: 20px;
  }
  .reportes .reportes-container .form-holder .form input {
    width: 100% !important;
  }
  .reportes .reportes-container .form-holder .selections-box {
    width: 100% !important;
    margin-bottom: 0 !important;
  }
  .reportes .reportes-container .selection-box {
    margin: 0px !important;
    margin-left: 15px !important;
    width: 100%;
    padding: 10px 0;
  }
  .reportes .table {
    min-width: 1350px !important;
    width: 100%;
  }
  .btn-box {
    display: flex;
    flex-direction: column;
  }
  .reportes .reportes-container p {
    font-size: 15px;
    padding: 2px 4px;
    width: 100%;
  }
  .reportes th:nth-child(1) {
    width: 50px !important;
  }
}

@media (max-width: 767px) {
  .reportes .reportes-container {
    max-width: 100%;
    margin-right: 0px !important;
    margin-top: 100px;
    padding: 20px;
  }
  .reportes .reportes-container .paginateComponent {
    flex-direction: column;
    margin: 0 12px 0 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .reportes .reportes-container .paginateComponent .pagination {
    justify-content: center !important;
  }
  .reportes .reportes-container .page-link {
    margin: 0 3px;
  }
  .finished table {
    margin: 0;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .reportes table {
    table-layout: fixed;
    min-width: 1400px;
    overflow: auto;
    width: 100%;
  }
  .reportes .reportes-container .paginateComponent {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
}
