section.register {
  margin: 0 !important;
  padding-top: 180px;
}

.register {
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  display: flex;
  justify-content: center;
  align-items: center;
}

.register .register-container {
  text-align: center;
}

.register .register-container .logo {
  width: 200px;
  margin-right: 0;
  display: inline-block;
  margin-bottom: 20px;
}

.register .register-container input,
.register .register-container .areaSelect {
  width: 400px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #cccdf1 !important;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.register .register-container .selection-box {
  border: none !important;
}

.register .register-container .input p,
.register .register-container .input .error {
  margin: 0 0 15px 0 !important;
  color: var(--primary-color) !important;
}

.register .register-container input:hover {
  border: 1px solid var(--primary-color);
}

.register .register-container button {
  color: #fff;
  background: var(--primary-color);
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.register .register-container p {
  margin: 20px 0;
}

.register .register-container .login {
  background: var(--secondary-color);
}

@media (max-width: 767px) {
  .register .register-container input,
  .register .register-container .areaSelect {
    width: 300px;
  }
}
