.newAppointment {
  margin: 0 !important;
}

/* POP UP STYLE */

.rejection-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: none;
  color: #fff;
  padding-top: 0 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 999;
  border-radius: 20px;
  overflow: hidden;
  width: 400px;
  height: auto;
}

.rejection-popup h1 {
  color: red;
}

.rejection-popup h4 {
  background-color: var(--primary-color);
  color: #fff;
  padding: 20px;
  font-size: 20px;
}

.rejection-popup-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.rejection-popup textarea {
  width: 100%;
  margin: 20px auto;
  height: 100px;
  padding: 15px;
}

.rejection-popup .popup-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
}

.newAppointment .col {
  width: 81% !important;
}

.newAppointment .newAppointment-container {
  position: relative;
  margin-right: 0 !important;
}

.newAppointment .newAppointment-container h2 {
  margin-bottom: 40px;
  color: var(--main-color);
}

.newAppointment .newAppointment-container .dataTable ul {
  background: #6c757d47;
  padding: 15px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  gap: 60px;
}

.newAppointment .newAppointment-container .firstbox,
.newAppointment .newAppointment-container .seconedbox {
  display: flex;
  gap: 45px;
}

.newAppointment .newAppointment-container .dataTable ul li {
  color: #000000;
  opacity: 0.5;
  font-weight: bold;
}

.newAppointment .newAppointment-container .dataTable img {
  width: 80px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  padding-top: 20px;
}

.newAppointment .newAppointment-container .dataTable p {
  padding: 40px 0;
}

.newAppointment .newAppointment-container .dataTable a {
  padding: 40px 0;
  display: block;
}

.newAppointment .newAppointment-container .dataTable .control-box a {
  width: 100% !important;
  padding: 0;
}

.newAppointment .newAppointment-container .dataTable .control-box button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.newAppointment .newAppointment-container .dataTable .control-box a button {
  width: 100%;
  background: transparent;
  border: 3px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: bold;
}

.newAppointment
  .newAppointment-container
  .dataTable
  .control-box
  button:nth-child(2) {
  margin-left: 0 !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .table {
    width: 100%;
  }
  .newAppointment .titles {
    min-width: max-content;
  }
  .newAppointment .firstCol {
    display: none;
  }
  .newAppointment .newAppointment-container .firstbox,
  .newAppointment .newAppointment-container .seconedbox {
    gap: 15px !important;
  }
  .newAppointment .newAppointment-container .dataTable ul {
    gap: 15px;
  }
}

@media (max-width: 767px) {
  .newAppointment .newAppointment-container .dataTable ul {
    flex-direction: column;
  }
  .newAppointment .newAppointment-container .firstbox,
  .newAppointment .newAppointment-container .seconedbox {
    flex-direction: column;
  }
  .newAppointment .newAppointment-container .firstbox div,
  .newAppointment .newAppointment-container .seconedbox div {
    display: flex;
    gap: 15px;
  }
  .newAppointment .newAppointment-container .dataTable p {
    padding: 0;
    margin-bottom: 0;
  }
  .newAppointment .newAppointment-container .dataTable a {
    padding: 0;
  }
  .newAppointment .col {
    width: 50% !important;
    margin-top: 100px;
  }
  .newAppointment .newAppointment-container .dataTable img {
    padding-top: 0;
  }
  .rejection-popup {
    flex-direction: column;
    width: 90%;
  }
  .rejection-popup-container {
    min-width: 100%;
  }
}
