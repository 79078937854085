section.nomVerification {
  margin-top: 0 !important;
  padding-top: 180px;
}

.nomVerification .nomVerification-container {
  padding: 20px;
  max-width: 420px;
  margin: 0px auto;
}

.nomVerification .nomVerification-container h3 {
  margin-bottom: 30px;
}

.nomVerification .nomVerification-container .intro-box p {
  width: 100%;
  color: #777;
  font-size: 14px;
  margin-top: 30px;
}

.nomVerification .nomVerification-container .inputBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nomVerification .nomVerification-container .inputBox input:nth-child(1) {
  width: 80%;
}

.nomVerification
  .nomVerification-container
  .inputBox
  input:nth-child(1)::placeholder {
  text-align: left;
}

.nomVerification .nomVerification-container .inputBox input:nth-child(2) {
  width: 20%;
  color: #777;
}

.nomVerification .nomVerification-container input {
  margin: 10px 0 20px 0;
  padding: 15px;
  width: 100%;
  border-radius: 8px;
}

.nomVerification .nomVerification-container input:focus {
  border-color: var(--primary-color);
}

.nomVerification .nomVerification-container button {
  padding: 15px;
  width: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.nomVerification .nomVerification-container h5 svg,
.nomVerification .nomVerification-container button svg {
  margin-left: 5px;
  font-size: 20px;
}

@media (max-width: 1024px) {
  .nomVerification .nomVerification-container {
    width: 100%;
  }
  .nomVerification .nomVerification-container input {
    width: 100%;
  }
}
