body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  line-height: 1.7;
  overflow: visible !important;
  overflow-x: hidden !important;
  direction: rtl;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none !important;
  box-sizing: border-box;
}

:root {
  --main-color: #142238;
  --primary-color: #0e8ece;
  --transition: 0.4s;
}

::-webkit-scrollbar {
  width: 2px;
  height: 5px;
  background: var(--main-color);
}
::-webkit-scrollbar-thumb {
  width: 50%;
  background: var(--primary-color);
  border-radius: 0.25rem;
}

@media (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: var(--main-color);
  }
  ::-webkit-scrollbar-thumb {
    width: 50%;
    background: var(--primary-color);
    border-radius: 0.25rem;
  }
}

/* General Style */

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

section {
  margin: 100px 0;
}

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

button {
  color: #fff;
  padding: 5px 15px;
  background: var(--primary-color);
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

button:hover {
  color: #fff;
  background: var(--main-color);
}

.no-data-message td {
  color: var(--primary-color);
  font-weight: bold;
}

input,
textarea {
  border: 1px solid #e7ddcf !important;
}

.selection-box {
  border: 1px solid #e7ddcf !important;
}

/* Loader Style */

.loader {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* SECTIONHEAD STYLE */

.section__head {
  text-align: center;
  margin-bottom: 80px;
}

.section__head span {
  font-size: 17px;
  display: inline-block;
  text-align: center;
  width: 600px;
  color: #5c3d03;
}

.section__head h2 {
  color: #000;
  font-size: 30px;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .section__head h2 {
    font-size: 25px;
  }
}

/* HeaderPage */

.headline {
  margin: 80px 0;
}

.headline h1 {
  font-size: 64px;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .headline h1 {
    font-size: 35px;
  }
  .headline p {
    font-size: 25px;
  }
}

@media screen and (max-width: 600px) {
  .headline h1 {
    font-size: 30px;
  }
  .headline p {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  h2 {
    font-size: 1.6rem;
  }
  .header__content {
    width: 76%;
  }
  .header__content h6 {
    width: fit-content;
  }
}

@media screen and (max-width: 600px) {
  .header__content h2 {
    font-size: 2.2rem;
    line-height: 1.3;
  }
  .header {
    height: fit-content;
  }
  section {
    margin-top: 0rem;
  }
  .header__content {
    width: var(--container-width-md);
    padding: 2rem 0;
  }
  .header__content h6 {
    font-size: 0.85rem;
    width: fit-content;
  }
}
