.scheduled .info h3 {
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scheduled table {
  table-layout: fixed;
  min-width: 1000px;
  overflow: auto;
  width: 100%;
}

thead,
th {
  position: sticky;
}

.scheduled button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.scheduled .printButton {
  margin-left: 30px;
}

.scheduled .newAppointment-container .form-holder {
  background-color: rgba(0, 0, 0, 0.125);
  padding: 20px;
  margin: 50px 0;
}

.scheduled .newAppointment-container .form-holder h5 {
  display: block;
  font-weight: bold;
}

.scheduled .newAppointment-container form {
  display: flex;
  justify-content: space-between;
}

.scheduled .newAppointment-container form button {
  width: 25%;
  height: 40px;
}

.scheduled .newAppointment-container input {
  border: none !important;
  padding: 10px;
  width: 25%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

@media (max-width: 767px) {
  .scheduled .btn-box {
    display: flex;
    flex-direction: row;
  }
  .scheduled .newAppointment-container form {
    flex-direction: column;
  }
  .scheduled .newAppointment-container input {
    width: 100%;
    margin-bottom: 15px;
  }
  .scheduled th:nth-child(3),
  .scheduled th:nth-child(4) {
    width: 70px !important;
  }
  .scheduled th:nth-child(1),
  .scheduled th:nth-child(2) {
    width: 87px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  body {
    font-size: 14px;
  }
  .table {
    width: 100%;
  }
  .scheduled td.btn-box {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: row;
  }
  .scheduled .btn-box button {
    padding: 5px 10px !important;
  }
  .scheduled .printButton {
    margin-left: 0px;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .scheduled table {
    table-layout: fixed;
    min-width: 1012px;
    overflow: auto;
    width: 100%;
  }
  .scheduled .printButton {
    margin-left: 0px;
  }
}
