.finished .info h3 {
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.finished table {
  table-layout: fixed;
  min-width: 1200px;
  overflow: auto;
  width: 100%;
}

thead,
th {
  position: sticky;
}

.finished .custom-checkbox {
  cursor: pointer !important;
}

.finished .btn-box {
  display: block;
}

.finished .firstBtnBox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.finished .firstBtnBox button {
  padding: 5px 10px;
  margin-bottom: 5px;
  width: 100% !important;
}

.finished .buttons {
  margin-left: 40px;
}

.finished .buttons button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.finished .btn-box button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border: 2px solid var(--primary-color);
}

.finished .btn-box button:hover {
  background: transparent !important;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.finished .btn-box a button {
  background: transparent !important;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.finished .btn-box a button:hover {
  background: var(--primary-color) !important;
  color: #fff;
}

.table > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: #d9d9d9;
}

.table tbody td {
  color: #414141 !important;
}

.finished .newAppointment-container .form-holder {
  background-color: rgba(0, 0, 0, 0.125);
  padding: 20px;
  margin: 50px 0;
}

.finished .newAppointment-container .form-holder h5 {
  display: block;
  font-weight: bold;
}

.finished .newAppointment-container form {
  display: flex;
  justify-content: space-between;
}

.finished .newAppointment-container form button {
  width: 25%;
  height: 40px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.finished .newAppointment-container .form-holder input {
  border: none !important;
  padding: 10px;
  width: 25%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* Popup container */

.finished .layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 5%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999 !important;
}

.finished-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  height: auto;
  min-width: 100px !important;
  z-index: 999999 !important;
}

.finished-popup .counter button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  padding: 4px 8px;
}

.finished-popup .counter span {
  padding: 0 5px;
}

.finished-popup .popup-content {
  background-color: #fff;
  border-radius: 10px;
  min-width: 100px !important;
  padding: 20px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 14%), 0px 8px 16px rgb(0 0 0 / 52%),
    0px -2px 2px rgb(255 255 255 / 0%);
}

.finished-popup .table-container {
  max-height: 300px;
}

.finished-popup table {
  border-collapse: collapse;
  min-width: 100px !important;
  width: 100% !important;
  table-layout: fixed;
}

.finished-popup table th,
table td {
  padding: 8px;
}

.finished-popup table th {
  background-color: #f2f2f2;
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
  .table {
    width: 100%;
  }
  .btn-box {
    display: flex;
    flex-direction: column;
  }
  .finished button {
    padding: 5px 8px;
  }
  .finished table {
    min-width: 1200px;
  }
  .finished .firstBtnBox button {
    padding: 5px 12px;
  }
  .finished .buttons {
    margin-left: 0px;
  }
  .finished-popup {
    width: 100%;
    height: 100vh;
    z-index: 99999 !important;
    transform: translate(-50%, -20%);
  }
  .finished-popup table th:nth-child(1) {
    width: 15%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .finished table {
    table-layout: fixed;
    min-width: 1400px;
    overflow: auto;
    width: 100%;
    margin: 0;
  }
  .finished .btn-box {
    display: block;
  }
  .finished button {
    padding: 5px 11px;
  }
  .finished .firstBtnBox {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }
  .finished .firstBtnBox button {
    width: 100%;
  }
  .finished th {
    width: 30px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .finished table {
    table-layout: fixed;
    min-width: 1400px;
    overflow: auto;
    width: 100%;
    margin: 0;
  }
  .finished .firstBtnBox {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }
  .finished .firstBtnBox button {
    width: 100%;
  }
}
