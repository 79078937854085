.sidebar {
  padding-top: 0px;
  /* position: fixed; */
  background: var(--main-color);
  color: #fff;
  overflow-y: auto;
  height: 100vh;
  z-index: 99;
  /* width: 100%; */
}

.sidebarSm {
  position: fixed;
  z-index: 999;
  margin: 15px;
  overflow: auto !important;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ccc rgba(0, 0, 0, 0.5);
}

.sidebarSm {
  display: none;
}

.sidebar .logo-box img,
.sidebarSm .logo-box img {
  width: 200px;
}

.sidebar h4,
.sidebarSm h4 {
  color: #fff;
  background: #182941;
  padding: 15px;
  font-weight: bold;
}

.offcanvas {
  background: var(--main-color);
}

/* .sidebar .active {
  color: blueviolet;
} */

.sidebar ul li,
.sidebarSm ul li {
  padding: 8px;
}

.sidebar ul li svg,
.sidebarSm ul li svg {
  margin-left: 8px;
}

.sidebar ul li a,
.sidebarSm ul li a {
  color: #fff;
  padding: 8px;
}

.sidebar .icon-box,
.sidebarSm .icon-box {
  padding-left: 8px !important;
}

.sidebar .logOut,
.sidebarSm .logOut {
  background: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
}

.sidebar .logOut button,
.sidebarSm .logOut button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  padding: 5px 30px;
}

.sidebarSm .btn {
  margin: 15px;
}

.offcanvas-body {
  padding: 0 !important;
}

.offcanvas-header .btn-close {
  font-size: 25px;
  border: none;
  outline: none;
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}

@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .sidebarSm {
    display: block;
  }
}
