.mobileNav {
  width: 100vw;
  display: none;
}

.mobileNav-container {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap !important;
}

.mobileNav button {
  background: transparent;
  border: none;
}

.mobileNav button svg {
  font-size: 20px;
}

.mobileNav .logo-box img {
  width: 200px;
}

.mobileNav h4 {
  color: #fff;
  background: #182941;
  padding: 15px;
  font-weight: bold;
}

.offcanvas {
  background: var(--main-color);
}

.mobileNav ul li {
  padding: 8px;
}

.mobileNav ul li svg {
  margin-left: 8px;
}

.mobileNav ul li a {
  color: #fff;
  padding: 8px;
}

.mobileNav .icon-box {
  padding-left: 8px !important;
}

.mobileNav .icon-box img {
  width: 24px;
  height: 24px;
}

.mobileNav .logOut {
  background: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
}

.mobileNav button {
  background: var(--primary-color);
  color: #fff;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.mobileNav button:hover {
  color: #fff !important;
}

.mobileNav .logOut button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  padding: 5px 30px;
}

.mobileNav .btn {
  margin: 15px;
}

.offcanvas-body {
  padding: 0 !important;
}

.offcanvas-header button {
  color: #fff;
  background: #000;
}

.offcanvas-header .btn-close {
  font-size: 25px;
  border: none;
  outline: none;
  filter: none;
  -webkit-filter: none;
  color: #fff !important;
  background: var(--primary-color) !important;
  opacity: 1;
}

.offcanvas-header .btn-close svg {
  color: #fff;
  font-size: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .sidebarSm {
    display: block;
  }
}

@media (max-width: 1024px) {
  .mobileNav {
    display: block;
  }
}
