.maintainceDone .info h3 {
  color: var(--primary-color);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maintainceDone table {
  table-layout: fixed;
  min-width: 1000px;
  overflow: auto;
  width: 100%;
}

thead,
th {
  position: sticky;
}

.maintainceDone .newAppointment-container .form-holder {
  background-color: rgba(0, 0, 0, 0.125);
  padding: 20px;
  margin: 50px 0;
}

.maintainceDone .newAppointment-container .form-holder h5 {
  display: block;
  font-weight: bold;
}

.maintainceDone .newAppointment-container .form-holder input {
  border: none !important;
  padding: 10px;
  width: 25%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.maintainceDone .newAppointment-container form {
  display: flex;
  justify-content: space-between;
}

.maintainceDone .newAppointment-container form button {
  width: 25%;
  height: 40px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.maintainceDone .newAppointment-container input {
  border: none !important;
}

.maintainceDone .buttons {
  margin-left: 40px;
}

.maintainceDone .buttons button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.maintainceDone .custom-checkbox {
  cursor: pointer !important;
}

.maintainceDone a button {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border: 2px solid var(--primary-color);
}

.maintainceDone a button:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.maintainceDone .city {
  width: 30px;
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
  .table {
    width: 100%;
  }
  .btn-box {
    display: flex;
    flex-direction: column;
  }
  .maintainceDone .newAppointment-container .form-holder input {
    width: fit-content;
  }
  .maintainceDone .buttons {
    margin-left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .table {
    width: 100%;
    margin-right: 5px;
  }
  .maintainceDone .newAppointment-container .form-holder input {
    width: fit-content;
  }
  .maintainceDone .buttons {
    margin-left: 0px;
  }
}
