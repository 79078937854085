section.brands {
  margin: 40px 0 !important;
  margin-bottom: 0 !important;
  padding: 50px 0;
}

.brands .brands-container .images-slider img {
  width: 75%;
  height: 170px;
  object-fit: contain;
  margin: 10px auto;
}
